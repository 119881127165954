/*TWITTER*/

.twitter-icon {
    position: absolute;
    top: 202px;
    left: -88px;
    animation: twitter 1.2s ease-in-out both;
}

@keyframes twitter {
    0% {top: 202px; left: 140px; rotate: 360deg; opacity: 0}
    100% {top: 202px; left: -88px; rotate: 0deg; opacity: 1}
}

/*FACEBOOK*/

.facebook-icon {
    position: absolute;
    top: 4px;
    left: -64px;
    animation: facebook 1.8s ease-in-out both;
}

@keyframes facebook {
    0% {top: 202px; left: 140px; rotate: 360deg; opacity: 0}
    33.33% {top: 202px; left: 140px; rotate: 360deg; opacity: 0}
    100% {top: 4px; left: -64px; rotate: 0deg; opacity: 1}
}

/*TIKTOK*/

.tiktok-icon {
    position: absolute;
    top: -78px;
    left: 126px;
    animation: tiktok 2.4s ease-in-out both;
}

@keyframes tiktok {
    0% {top: 202px; left: 140px; rotate: 360deg; opacity: 0}
    50% {top: 202px; left: 140px; rotate: 360deg; opacity: 0}
    100% {top: -78px; left: 126px; opacity: 1}
}

/*YOUTUBE*/

.youtube-icon {
    position: absolute;
    top: -55px;
    right: -5px;
    animation: youtube 3.0s ease-in-out both;
}

@keyframes youtube {
    0% {top: 202px; right: 190px; rotate: 360deg; opacity: 0}
    60% {top: 202px; right: 190px; rotate: 360deg; opacity: 0}
    100% {top: -55px; right: -5px; opacity: 1}
}

/*INSTAGRAM*/

.instagram-icon {
    position: absolute;
    top: 159px;
    right: -43px;
    animation: instagram 3.6s ease-in-out both;
}

@keyframes instagram {
    0% {top: 202px; right: 190px; rotate: 360deg; opacity: 0}
    66.66% {top: 202px; right: 190px; rotate: 360deg; opacity: 0}
    100% {top: 159px; right: -43px; opacity: 1}
}

@media screen and (min-width: 0px ) and (max-width: 599px) {

    /*TWITTER*/

    .twitter-icon {
        width: 71px;
        top: 102px;
        left: -72px;
        animation: twitter2 1.2s ease-in-out both;
    }

    @keyframes twitter2 {
        0% {top: 102px; left: 60px; rotate: 360deg; opacity: 0}
        100% {top: 102px; left: -72px; rotate: 0deg; opacity: 1}
    }

    /*FACEBOOK*/

    .facebook-icon {
        width: 77px;
        top: -20px;
        left: -50px;
        animation: facebook2 1.8s ease-in-out both;
    }

    @keyframes facebook2 {
        0% {top: 102px; left: 60px; rotate: 360deg; opacity: 0}
        33.33% {top: 102px; left: 60px; rotate: 360deg; opacity: 0}
        100% {top: -20px; left: -50px; rotate: 0deg; opacity: 1}
    }

    /*TIKTOK*/

    .tiktok-icon {
        width: 79px;
        top: -68px;
        left: 60px;
        animation: tiktok2 2.4s ease-in-out both;
    }

    @keyframes tiktok2 {
        0% {top: 102px; left: 60px; rotate: 360deg; opacity: 0}
        50% {top: 102px; left: 60px; rotate: 360deg; opacity: 0}
        100% {top: -68px; left: 60px; opacity: 1}
    }

    /*YOUTUBE*/

    .youtube-icon {
        width: 97px;
        top: -30px;
        right: -20px;
        animation: youtube2 3.0s ease-in-out both;
    }

    @keyframes youtube2 {
        0% {top: 102px; right: 85px; rotate: 360deg; opacity: 0}
        60% {top: 102px; right: 85px; rotate: 360deg; opacity: 0}
        100% {top: -30px; right: -20px; opacity: 1}
    }

    /*INSTAGRAM*/

    .instagram-icon {
        width: 89px;
        top: 80px;
        right: -30px;
        animation: instagram2 3.6s ease-in-out both;
    }

    @keyframes instagram2 {
        0% {top: 102px; right: 85px; rotate: 360deg; opacity: 0}
        66.66% {top: 102px; right: 85px; rotate: 360deg; opacity: 0}
        100% {top: 80px; right: -30px; opacity: 1}
    }
}
