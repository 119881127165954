.activeLink:after {
  border-bottom: 2px solid #ebca7d;
  content: '';
  display: block;
  margin-left: 25%;
  width: 50%;
}

.passiveLink:hover {
  border-bottom: 2px solid #ebca7d;
  content: '';
  display: block;
}
@media (max-width: 1420px) {
  .css-p95571 {
    width: 100% !important;
  }
}
@media (max-width: 528px) {
  .css-k008qs {
    width: 100% !important;
  }
  .css-1680k5y {
    padding: 10px !important;
  }
  .css-1qhhl09 {
    margin-top: 10px !important;
  }
  .css-1kdnnoe-MuiTypography-root {
    line-height: 18px !important;
  }
  .css-b8kzem {
    padding-bottom: 4px !important;
  }
  .css-1b6n4o1 {
    margin-right: 4px !important;
  }
}

@media (max-width: 570px) {
  .css-1680k5y {
    height: 100% !important;
  }
  .css-rmfte {
    height: 100% !important;
    padding: 10px !important;
  }
}

@media (max-width: 1200px) {
  .css-jvu42c {
    display: revert !important;
  }
  .css-13gczhn-MuiTypography-root {
    text-align: center !important;
  }

  .css-14a169l-MuiTypography-root {
    text-align: center !important;
  }
  .css-336oea-MuiTypography-root {
    text-align: center !important;
  }
  .css-p95571 {
    width: 100% !important;
  }
  /* .css-0 {
    margin-bottom: 20px !important;
  } */
  .css-jvu42c {
    margin-top: 20px !important;
  }
  .css-1680k5y {
    width: 100% !important;
  }
  .css-1p5l29z {
    align-items: center !important;
  }
  .css-10yeye-MuiButtonBase-root-MuiButton-root {
    display: none !important;
  }
}
