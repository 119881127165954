.newsletter,
::placeholder {
  font-family: 'GillSansRegular', sans-serif;
  width: 100%;
  height: 53px;
  background-color: #313131;
  border: none;
  border-radius: 60px;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  color: rgba(255, 255, 255, 0.53);
  padding-left: 12px;
  position: relative;
}

.button {
  font-family: 'GillSansRegular', sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: normal;
  line-height: 26px;
  color: #222222;
  background-color: #e9c478;
  width: 116px;
  height: 53px;
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  border-radius: 60px;
  cursor: pointer;
}
