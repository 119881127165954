.inp {
  position: relative;
  width: 100%;
  font-family: 'Gill Sans', sans-serif;
  font-size: 16px;
  line-height: 31px;
  font-weight: 400;
  color: #eac67a;
  background-color: #373737;
  border-style: none;
  padding-bottom: 19px;
  padding-left: 16px;
  flex-grow: 1;
}

.inp::placeholder {
  position: absolute;
  top: 0;
  left: -12px;
  font-size: 19px;
  line-height: 31px;
  font-weight: 400;
  color: #fff;
  opacity: 40%;
  background-color: #373737;
}

.inp-box {
  border-bottom: 1px solid rgba(255, 255, 255, 0.17);
}

.inp-box:hover {
  border-bottom: 1px solid #eac67a;
}

.select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #373737 url('../../assets/select-arrow.svg') no-repeat right 8px;
  position: relative;
  font-family: 'Gill Sans', sans-serif;
  font-size: 19px;
  line-height: 31px;
  font-weight: 400;
  color: #fff;
  border-style: none;
  padding-bottom: 12px;
  padding-left: 16px;
  cursor: pointer;
  flex-grow: 1;
}

.label {
  display: block;
  position: relative;
  padding-left: 26px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: 'Gill Sans', sans-serif;
  font-size: 19px;
  font-weight: 400;
  line-height: 31px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  color: #fff;
}

.label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #373737;
  border: 1px solid #9b9b9b;
  border-radius: 50%;
}

.label:hover input ~ .checkmark {
  background-color: #eac67a;
}

.label input:checked ~ .checkmark {
  background-color: #eac67a;
  border: 1px solid #eac67a;
}

.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

.label input:checked ~ .checkmark:after {
  display: block;
}

.label .checkmark:after {
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
