raised-button--rounded,
.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  color: #eac67a !important;
}

.css-1ald77x {
  color: #eac67a !important;
}
.css-p0rm37.Mui-error {
  color: #858585 !important;
}
.css-1ald77x.Mui-error {
  color: #858585 !important;
}

.css-1ald77x.Mui-focused {
  color: #eac67a !important;
}

.css-1bn53lx.Mui-error .MuiOutlinedInput-notchedOutline {
  border-color: #878787 !important;
  color: red !important;
}
.css-1bn53lx {
  color: #eac67a !important;
}
.css-1bn53lx.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #878787 !important;
  color: #eac67a !important;
}

.css-segi59.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #878787 !important;
}

raised-button--rounded,
.css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root {
  color: #878787 !important;
}
.css-i4bv87-MuiSvgIcon-root {
  color: #878787 !important;
}
.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root {
  border-color: 1px solid rgba(255, 255, 255, 0.17) !important;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
  color: white !important;
}
.label#combo-box-demo-label {
  color: white !important;
}

.label#combo-box-demo-label .Mui-focused {
  color: white !important;
}
input#combo-box-demo {
  color: #eac67a;
}

svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeMedium.css-vubbuv {
  color: #878787;
}
svg.MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall.css-1k33q06 {
  color: #878787;
}

.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #eac67a !important;
}
.css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
  .MuiOutlinedInput-notchedOutline {
  border-color: red !important;
}
fieldset.MuiOutlinedInput-notchedOutline.css-igs3ac {
  border-color: #878787;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  border-color: rgba(255, 255, 255, 0.17) !important;
}
.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-family: 'Gill Sans', sans-serif;
  font-size: 18px;
  line-height: 27px;
  font-weight: 400;
  color: #fff;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.check {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  border: 1px solid #a7894b;
  border-radius: 5px;
  background-color: #eac67a;
}

.container:hover input ~ .check {
  background-color: #ccc;
}

.container input:checked ~ .check {
  background-color: #eac67a;
}

.check:after {
  content: '';
  position: absolute;
  display: none;
}

.container input:checked ~ .check:after {
  display: block;
}

.container .check:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 700px) {
  .num {
    flex-direction: column;
  }
  .Zdbox {
    width: 100% !important;
  }
}
